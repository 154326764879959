class RectangleExtents {
	public minX: number = 0;
	public maxX: number = 0;
	public minY: number = 0;
	public maxY: number = 0;

	public overlaps(extents: RectangleExtents): boolean {
		if (this.minX > extents.maxX || extents.minX > this.maxX) {
			return false;
		}

		if (this.minY > extents.maxY || extents.minY > this.minY) {
			return false;
		}

		return true;
	}
}

export default RectangleExtents;