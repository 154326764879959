import React, { useState, useEffect } from 'react';
import MinusIcon from '../../../../images/Ikon-Minus.png'
import PlusIcon from '../../../../images/Ikon-Plus.png'
import { Box, IconButton, Typography } from '@material-ui/core';

interface MenuTogglerProps {
    openDefault: boolean;
    title: string;
    onToggle: (open: boolean) => void;
}

const MenuToggler: React.FC<MenuTogglerProps> = (props) => {
    const [open, setOpen] = useState(props.openDefault);

    useEffect(() => {
        props.onToggle(open);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Box
            display="flex"
            marginLeft="-9px"
            paddingTop={2}
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                setOpen(prev => !prev);
            }}
        >
            <Box>
                <IconButton size="small">
                    {open ? <img src={MinusIcon} alt="Minus Ikon" width="95%" /> : <img src={PlusIcon} alt="Plus Ikon" width="95%" />}
                </IconButton>
            </Box>
            <Box textAlign="left" paddingLeft="15px">
                <Typography variant="subtitle2" color="primary" style={{ fontWeight: 'bolder' }}>{props.title}</Typography>
            </Box>
        </Box>
    );
};

export default MenuToggler;
