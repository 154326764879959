import React, { useState, useEffect } from 'react';
import NavigationMenu from '../../../components/NavigationMenu/NavigationMenu';
// import ChatBot from '../../../components/ChatBot/ChatBot';
import ContentBody from '../../../components/ContentBody/ContentBody';
import Graph from '../../../components/Graph/Graph';
import { Box } from '@material-ui/core';
import { GlossaryItem, ItemReferences } from '../../../types/glossary';
import glossary from '../../../data/glossar.json';
import glossarImages from '../../../data/contentImages.json';
import { useHistory } from 'react-router-dom';
import { HomePaths } from '../../../paths/HomePaths';
import { useStyles } from '../../../hooks/useStyles';

interface GlossaryPageProps {
	topicId?: number;
	setBackgroundImageUrl: (url: string) => void;
	mobileDrawerOpen: boolean;
	closeMobileDrawer: () => void;
}

const introductionItem: GlossaryItem = {
	id: 0,
	title: 'Einführung',
	titleLong: 'Das KI-Glossar für intelligentes Planen und Bauen',
	// backgroundImage: '/static/images/Fullscreen-BG-Image.jpg',
	backgroundImage: '/static/images/20200723_eBZ_2020_KI_Keyvisual.jpg',
	description: '<p>Künstliche Intelligenz (KI) ist eine der Schlüsseltechnologien der Zukunft, die als Querschnitttechnologie Einfluss auf alle Bereiche der Wirtschaft haben wird. Möchten auch Sie die Vorteile von KI in Ihrem Betrieb nutzbar machen oder haben Sie einfach Fragen zum Thema KI für den Mittelstand im Bau- und Ausbauhandwerk? Dann nutzen Sie unser umfassendes Serviceangebot, das für Sie, dank öffentlicher Förderung, komplett kostenfrei ist.</p><p>Kontaktieren Sie dazu gerne unsere KI-Trainer.</p>',
	// video: 'https://www.youtube.com/watch?v=HWZmZR-AmsY',
	category: '',
	in_links: [],
	out_links: [],
};

const glossaryItems: GlossaryItem[] = glossary.topics.map(topic => {
	const imgName = glossarImages.images.find(ci => ci.id === topic.id);
	return {
		id: topic.id,
		title: topic.title,
		description: topic.description,
		image: imgName ? '/static/images/content/' + imgName.image : undefined,
		video: topic.video,
		category: topic.Category_web ? topic.Category_web : '',
		in_links: topic.in_links,
		out_links: topic.out_links,
	}
});

const GlossaryPage: React.FC<GlossaryPageProps> = (props) => {
	const classes = useStyles(theme => ({
		graph: {
			maxHeight: 'calc(100vh - 120px)',
			[theme.breakpoints.down("md")]: {
				display: "none",
			},
		}
	}));


	const [selectedItem, setSelectedItem] = useState<GlossaryItem>(introductionItem);
	const [referencedItems, setReferencedItems] = useState<GlossaryItem[]>([]);
	const history = useHistory();

	useEffect(() => {
		let currentItem = introductionItem;

		if (props.topicId) {
			const parameterItem = getItemByTopicId(props.topicId);
			if (parameterItem) currentItem = parameterItem;
		}

		switchToSelectedItem(currentItem, false);

		// eslint-disable-next-line
	}, [props.topicId]);

	const switchToSelectedItem = (item: GlossaryItem, push: boolean) => {
		setSelectedItem(item);
		console.log(item.image);

		const newReferencedItems: GlossaryItem[] = [];
		addLinksToItemList(newReferencedItems, item.in_links);
		addLinksToItemList(newReferencedItems, item.out_links);
		setReferencedItems(newReferencedItems);

		props.setBackgroundImageUrl(item.backgroundImage ?? '');
		if (push) history.push(HomePaths.Glossary + "/" + item.id);
	}

	const addLinksToItemList = (itemList: GlossaryItem[], links: ItemReferences[]) => {
		links.forEach((link) => {
			link.topicIds.forEach((topicId) => {
				if (itemList.findIndex(item => item.id === topicId) === -1) {
					const rItem = getItemByTopicId(topicId);
					if (rItem) itemList.push(rItem);
				}
			});
		});
	}

	const getItemByTopicId = (topicId: number) => {
		return glossaryItems.find((item) => item.id === topicId);
	}

	return (
		<React.Fragment>
			<div>
				<NavigationMenu
					topItems={[introductionItem]}
					glossaryItems={glossaryItems}
					selectedItem={selectedItem}
					onSelect={switchToSelectedItem}
					mobileDrawerOpen={props.mobileDrawerOpen}
					closeMobileDrawer={props.closeMobileDrawer}
				/>
			</div>
			<Box flexGrow={1}>
				<ContentBody selectedItem={selectedItem} mainPage={selectedItem.id === introductionItem.id} />
			</Box>
			<div className={classes.graph}>
				{
					<Graph
						selectedItem={selectedItem}
						referencedItems={referencedItems}
						onSelect={switchToSelectedItem}
					/>}
			</div>
			{/* <ChatBot /> */}
		</React.Fragment>
	);
};

export default GlossaryPage;
