import React, { useState } from 'react';

import AppBar from '../../components/AppBar/AppBar';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Footer from '../../components/Footer/Footer';
import { Switch, Route, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import GlossaryPage from './GlossaryPage/GlossaryPage';
import { HomePaths } from '../../paths/HomePaths';

import Impressum from './Impressum';
import Datenschutz from './Datenschutz';

// const bgOverlay = 'linear-gradient(to right top, #00000030,  #00000020,  #00000020), radial-gradient( #ffffff45, #4bbd9110), linear-gradient(to bottom right, #9ed7d1, #f5f5f520, #f4f4f420)';
export const bgOverlay = 'linear-gradient(to right top, #00000030,  #00000020,  #00000020), radial-gradient( #ffffff45, #4bbd9110), linear-gradient(to bottom right, #c6e3f2, #f5f5f520, #f4f4f420)';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		body: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'stretch',
			backgroundImage: bgOverlay,
			minHeight: 'calc(100vh - 120px)',
		}
	}),
);

const Home: React.FC = () => {
	const classes = useStyles();
	const [backgroundImageUrl, setBackgroundImageURL] = useState('');
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
	let location = useLocation();

	const renderGlossaryPage = (routeProps: RouteComponentProps<any, any, any>) => {
		const topicId = routeProps.match.params.id;
		return <GlossaryPage
			setBackgroundImageUrl={setBackgroundImageURL}
			topicId={Number(topicId)}
			mobileDrawerOpen={mobileDrawerOpen}
			closeMobileDrawer={() => setMobileDrawerOpen(false)}
		/>;
	}

	const renderOtherPage = (page: "impressum" | "datenschutz") => (routeProps: RouteComponentProps<any, any, any>) => {
		setBackgroundImageURL('');
		switch (page) {
			case 'datenschutz':
				return <Datenschutz />
			case 'impressum':
				return <Impressum />;
			default:
				return <p>Seite nicht bekannt.</p>;
		}

	}

	const body = () => {
		return (
			<div className={classes.body} style={{
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundImage: `url(${backgroundImageUrl}), ` + bgOverlay,
				// backgroundImage: bgOverlay + `, url(${backgroundImageUrl})`
			}}>
				<Switch location={location}>
					<Route path={HomePaths.Glossary} exact render={renderGlossaryPage} />
					<Route path={HomePaths.Glossary + '/:id'} render={renderGlossaryPage} />
					<Route path={HomePaths.Impressum} render={renderOtherPage('impressum')} />
					<Route path={HomePaths.Datenschutz} render={renderOtherPage('datenschutz')} />
					<Redirect to={HomePaths.Glossary} />
				</Switch>
			</div>
		);
	}

	const steps = [
		{
			id: '0',
			message: 'Welcome to react chatbot!',
			trigger: '1',
		},
		{
			id: '1',
			message: 'Bye!',
			end: true,
		},
	];

	return (
		<React.Fragment>
			<AppBar openMobileDrawer={() => setMobileDrawerOpen(true)} />
			{body()}
			<Footer />
		</React.Fragment>
	);
};

export default Home;
