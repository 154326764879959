import React from 'react';
import { GlossaryItem } from '../../types/glossary';
import { Typography, Container } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { useStyles } from '../../hooks/useStyles';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from './ContentBody.module.scss';

interface ContentBodyProps {
	selectedItem: GlossaryItem;
	mainPage?: boolean;
}

const ContentBody: React.FC<ContentBodyProps> = (props) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.up('md'));

	const classes = useStyles(theme => ({
		normalTitle: {
			marginBottom: theme.spacing(4),
			color: theme.palette.primary.main,
			// fontWeight: 'bold',
		},
		mainTitle: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(4),
			color: '#5cc4ff', //theme.palette.secondary.main,
			fontWeight: 500,
			[theme.breakpoints.down("md")]: {
				marginTop: 0,
				fontSize: '36px',
			}
		},
		mainDescription: {
			color: '#fff',
		},
		img: {
			//paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(4),
		},
		description: {
			textAlign: "justify",
			lineHeight: 1.7,
			marginTop: "12px",
		},
		scrollContainer: {
			maxHeight: 'calc(100vh - 120px)',
			//maxHeight: `calc(100vh - ${fullHeightRef?.current?.offsetTop ?? 0}px)`,
			maxWidth: "800px",
			[theme.breakpoints.down("md")]: {
				paddingLeft: theme.spacing(1),
				paddingRight: theme.spacing(1),
			},
			overflow: 'scroll',
		},
		contentStyle: {
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(4),
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "start",
		},
	}));

	return (
		<Container>
			{/* <SimpleBar className={classes.scrollContainer}> */}
			<div className={
				clsx({
					[classes.scrollContainer]: matches
				}, classes.contentStyle, 'hideScrollbar')
			}>
				<Typography variant="h3" className={clsx({
					[classes.normalTitle]: !props.mainPage,
					[classes.mainTitle]: props.mainPage,
				})}>
					{props.selectedItem.titleLong ? props.selectedItem.titleLong : props.selectedItem.title}
				</Typography>
				{props.selectedItem.video ?
					<div className={styles.playerWrapper}>
						<ReactPlayer className={styles.reactPlayer}
							width="100%"
							height="100%"
							url={props.selectedItem.video} controls />
					</div>
					: null}
				{props.selectedItem.image ?
					<img className={classes.img} src={props.selectedItem.image} alt={props.selectedItem.title} width="100%" /> : null}
				<div
					className={clsx(
						classes.description,
						{
							[classes.mainDescription]: props.mainPage,
						})}
					dangerouslySetInnerHTML={{ __html: props.selectedItem.description }}></div>
			</div>
			{/* </SimpleBar> */}
		</Container >
	);
};

export default ContentBody;
