import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import MenuToggler from './MenuToggler/MenuToggler';
import MenuList from './MenuList/MenuList';


interface NavMenuProps {
    menuTitle: string;
    topItems?: string[];
    items: string[];
    useInitials?: boolean;
    selectedItems: string[];
    highlightHover?: boolean;
    highlightedItems: string[];
    isOpenByDefault?: boolean;
    onSelect: (item: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menu: {
            overflowY: 'scroll',
            paddingLeft: 10,
            flexGrow: 1,
        },
        menuHidden: {
            overflow: 'hidden',
            maxHeight: 60,
        },
        menuItems: {
            maxHeight: 3000,
            transition: 'max-height 0.2s ease-in-out',
        },
        menuItemsHidden: {
            maxHeight: 0,
        },
    }),
);

const NavMenu: React.FC<NavMenuProps> = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(props.isOpenByDefault ? true : false);
    return (
        <div
            className={clsx(
                classes.menu,
                { [classes.menuHidden]: !open },
                'hideScrollbar'
            )}
        >
            <MenuToggler title={props.menuTitle} openDefault={open} onToggle={(val) => { setOpen(val) }} />
            <div className={
                clsx(
                    classes.menuItems,
                    { [classes.menuItemsHidden]: !open }
                )
            }>
                <MenuList
                    topItems={props.topItems ? props.topItems : []}
                    items={props.items}
                    useInitials={props.useInitials}
                    highlightHover={props.highlightHover}
                    selectedItems={props.selectedItems}
                    highlightedItems={props.highlightedItems}
                    onSelect={props.onSelect}
                />

            </div>
        </div>
    );
};

export default NavMenu;
