import Particle from "./Particle";

class Line {
	private width = 1 * window.devicePixelRatio;
	private rgbColor = "100, 100, 100";
	private startParticle: Particle;
	private endParticle: Particle;

	constructor(startParticle: Particle, endParticle: Particle) {
		this.startParticle = startParticle;
		this.endParticle = endParticle;
	}

	public renderFrame(canvasContext: CanvasRenderingContext2D) {
		let opacity = Math.max(1 - (this.startParticle.pos.distanceTo(this.endParticle.pos) / window.devicePixelRatio) / 142, 0);
		if (opacity > 0) {
			canvasContext.lineWidth = this.width;
			canvasContext.strokeStyle = `rgba(${this.rgbColor}, ${opacity})`;
			canvasContext.beginPath();
			canvasContext.moveTo(this.startParticle.pos.x, this.startParticle.pos.y);
			canvasContext.lineTo(this.endParticle.pos.x, this.endParticle.pos.y);
			canvasContext.stroke();
		}
	}
}

export default Line;