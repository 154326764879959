class Vector2 {
	public x: number;
	public y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	public get length(): number {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}

	public add(vector: Vector2): Vector2 {
		return new Vector2(this.x + vector.x, this.y + vector.y);
	}

	public subtract(vector: Vector2): Vector2 {
		return new Vector2(this.x - vector.x, this.y - vector.y);
	}

	public scale(factor: number): Vector2 {
		return new Vector2(this.x * factor, this.y * factor);
	}

	public distanceTo(vector: Vector2): number {
		return this.subtract(vector).length;
	}
}

export default Vector2;