import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Link, Grid, Hidden } from '@material-ui/core';
import { LinkRef } from '../common/Link/Link';

import Mittelstand40_logo from '../../images/Mittelstand40.png';
import BMWE_gefördert_Logo from '../../images/BMWE_gefoerdert_Logo-grau.jpg';
import { HomePaths } from '../../paths/HomePaths';

import clsx from 'clsx';
import styles from './Footer.module.scss';

interface FooterProps {
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			backgroundColor: '#cccccc',
			color: '#3b3b3b',
		},
	}),
);

const Footer: React.FC<FooterProps> = (props) => {
	const classes = useStyles();
	return (
		<footer className={clsx(classes.root, styles.footerShadow)}>
			<Box display="flex" alignItems="center" px={2} py={2}>

				<Grid container spacing={1} alignItems="center" justify="center" >
					<Grid item sm={6} md={2} style={{ height: "100%" }}>

						<Box px="30px" my="15px" textAlign="center"><img src={Mittelstand40_logo} alt="Mittelstand Digital Logo" height={40} /></Box>
					</Grid>
					<Grid item sm={6} md={2}>
						<Box px="30px" my="15px" textAlign="center"><img src={BMWE_gefördert_Logo} alt="Bmwi  Logo" height="120px" /></Box>
					</Grid>
					<Grid item sm={12} md={5}>
						<Hidden smDown>
							<Box textAlign="justify" px={4} fontSize="small"> <p>Das Mittelstand 4.0-Kompetenzzentrum Planen und Bauen ist Teil der Förderinitiative „Mittelstand-Digital – Strategien zur digitalen Transformation der Unternehmensprozesse“,  die vom Bundesministerium für Wirtschaft und Energie gefördert wird.</p> </Box>
						</Hidden>
						<Hidden mdUp>
							<Box textAlign="justify" px={0} fontSize="small"> <p>Das Mittelstand 4.0-Kompetenzzentrum Planen und Bauen ist Teil der Förderinitiative „Mittelstand-Digital – Strategien zur digitalen Transformation der Unternehmensprozesse“,  die vom Bundesministerium für Wirtschaft und Energie gefördert wird.</p> </Box>
						</Hidden>

					</Grid>
					<Grid item container sm={12} md={2} justify="center">
						<Grid item>
							<Box px={1}>
								<Link
									component={LinkRef} to={HomePaths.Impressum}
								>Impressum</Link>
							</Box>
						</Grid>
						<Grid item>
							<Box px={1} borderLeft="solid 1px #3b3b3b">
								<Link
									component={LinkRef} to={HomePaths.Datenschutz}
								>Datenschutz</Link>
							</Box>
						</Grid>
					</Grid>
				</Grid>


			</Box>

		</footer >
	);
};

export default Footer;
