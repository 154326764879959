import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Box, AppBar as MuiAppBar, Toolbar, Hidden, IconButton, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EmailIcon from '@material-ui/icons/EmailOutlined';

import KI_logo from '../../images/Stoerer_KI-Mittelstand.png'
import Mittelstand_logo from '../../images/logo_md40_kompetenzzentrum_planen_bauen_cut.svg';

import clsx from 'clsx';
import styles from './AppBar.module.scss';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			backgroundColor: '#ffffff',
		},
		mittelstandlogoContainer: {
			maxHeight: 45,
			width: "50%",
			[theme.breakpoints.up("lg")]: {
				marginLeft: 48,
			},
			marginTop: 30,
			marginBottom: 30,
		},
		mittelstandLogo: {
			maxHeight: '40px', height: '100%', maxWidth: '100%'
		},
		kiLogo: {
			//backgroundColor: '#232323',
			//borderRadius: "50%",
			width: 150,
			height: 150,
			overflowY: "visible",
			position: 'absolute',
			top: 20,
			left: '50%',
			marginX: 'auto',
			transform: 'translate(-50%, -0%)',
		},
		kiLogoSmallContainer: {
			// display: 'block',
			// position: 'relative',
			// color: 'white',
			alignContent: 'center',
			minWidth: 60,
			minHeight: 60,
			margin: '10px 15px',
			padding: 0,
			//margin: '0 15px'
		},
		kiLogoSmall: {
			maxHeight: '100px', maxWidth: '100px', width: '80%',
		},
		menuButton: {
			marginTop: 36,
			marginBottom: 36,
			fontSize: 36,
		}
	}),
);

interface AppBarProps {
	openMobileDrawer: () => void;
}

const AppBar: React.FC<AppBarProps> = (props) => {
	const classes = useStyles();

	const location = useLocation();
	const hideMenu = location.pathname.includes('impressum') || location.pathname.includes('datenschutz');

	return (
		<React.Fragment>
			<div className={classes.root}>
				<MuiAppBar position="static" color="transparent" elevation={0}>
					<Toolbar>
						<Hidden mdUp>
							{
								hideMenu ? null : <IconButton onClick={props.openMobileDrawer} className={classes.menuButton}>
									<MenuIcon />
								</IconButton>
							}
						</Hidden>
						<div className={classes.mittelstandlogoContainer}>
							<img className={classes.mittelstandLogo}
								src={Mittelstand_logo} alt="Mittelstand 4.0 Kompetenzzentrum für Planen und Bauen" />
						</div>


						<Hidden mdUp>
							<div className={classes.kiLogoSmallContainer}>
								<img className={classes.kiLogoSmall} src={KI_logo} alt="KI Plattform Logo" />
							</div>
						</Hidden>
						<Hidden smDown>
							<div className={classes.kiLogo}>
								<img src={KI_logo} alt="KI Plattform Logo" width="100%" />
							</div>
						</Hidden>

						<Box flexGrow={1}></Box>
						<Hidden mdUp>
							<Link
								href='https://www.kompetenzzentrum-planen-und-bauen.digital/kos/WNetz?art=Person.show&id=60'
								style={{ marginRight: 6 }}
								className={clsx(styles.contactButton)}
								target='blank'
							>
								<EmailIcon />
							</Link>
						</Hidden>
						<Hidden smDown>
							<Link
								href='https://www.kompetenzzentrum-planen-und-bauen.digital/kos/WNetz?art=Person.show&id=60'
								style={{ marginRight: 6 }}
								className={clsx(styles.contactButton)}
								target='blank'
							>
								KONTAKT
						</Link>
						</Hidden>

					</Toolbar>
				</MuiAppBar>

			</div>



		</React.Fragment>
	);
};

export default AppBar;
