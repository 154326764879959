import React, { useState, useEffect } from 'react';
import { FormControl, OutlinedInput, InputAdornment, IconButton, makeStyles, Theme, createStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/SearchOutlined';

interface SearchInputProps {
    searchChanged: (searchTerm: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchField: {
            backgroundColor: '#ffffff',
            borderWidth: 0,
            borderRadius: '15px 15px  3px 15px',
        }
    }),
);

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        props.searchChanged(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    const hasSearch = (): boolean => {
        return searchTerm.length > 0;
    }

    return (
        <FormControl variant="filled" size="small" fullWidth>
            <OutlinedInput
                margin="dense"
                className={classes.searchField}
                id="outlined-adornment-weight"
                value={searchTerm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchTerm(event.target.value);
                }}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        aria-label="Suche nach Themen"
                        onClick={() => {
                            setSearchTerm('');
                        }}
                        edge="end"
                        disabled={!hasSearch()}
                    >
                        {hasSearch() ?
                            <ClearIcon /> :
                            <SearchIcon />
                        }
                    </IconButton>
                </InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'suche',
                }}
            />
        </FormControl>
    );
};

export default SearchInput;
