import Vector2 from "./Vector2";
import { GlossaryItem } from "../../../types/glossary";
import TWEEN from "@tweenjs/tween.js";
import RectangleExtents from "./RectangleExtents";

interface Color {
	r: number;
	g: number;
	b: number;
}

const PRIMARY_NODE_COLOR = { r: 181, g: 9, b: 0 };
const MAIN_NODE_COLOR = { r: 9, g: 19, b: 45 };
const BACKGROUND_NODE_COLOR = { r: 100, g: 100, b: 100 };

const RADIUS_VARIABLE = 1;
const RADIUS_CONSTANT = 3;
const FRICTION_FACTOR_VARIABLE = 0.05;
const FRICTION_FACTOR_CONSTANT = 0.92;
const ACCELERATION_FACTOR = 1 / 1000;
const ANIMATION_DURATION = 1000;
const CIRCLE_RADIUS_FACTOR = 4;
const BACKGROUND_POINT_RADIUS_FACTOR = 0.6;

class Particle {
	public get pos() { return this.position; }
	public get dest() { return this.destination; }
	public get maxHeight() { return this.circleRadius * 2; }

	private position: Vector2;
	private destination: Vector2;
	private velocity = new Vector2(0, 0);
	private acceleration = new Vector2(0, 0);
	private mainPointRadius = (Math.random() * RADIUS_VARIABLE + RADIUS_CONSTANT) * window.devicePixelRatio;
	private circleRadius = this.mainPointRadius * CIRCLE_RADIUS_FACTOR;
	private pointRadius = this.mainPointRadius * BACKGROUND_POINT_RADIUS_FACTOR;
	private mainRadiusDelta = this.mainPointRadius - this.pointRadius;
	private frictionFactor = (Math.random() * FRICTION_FACTOR_VARIABLE + FRICTION_FACTOR_CONSTANT);
	private circleMargin = 8 * window.devicePixelRatio;
	private rectanglePadding = 4 * window.devicePixelRatio;
	private mainColor = MAIN_NODE_COLOR;
	private renderedMainColor = this.mainColor;
	private item: GlossaryItem | undefined;
	private renderedItem: GlossaryItem | undefined;
	private primaryNode = false;

	private rightOffset = 0;
	private rightDestinationOffset = 0;
	private maxRadius = 0;

	private mainFactor = 0;
	private tween = new TWEEN.Tween({ mainFactor: this.mainFactor });
	private textMetrics: TextMetrics | undefined;

	constructor(position: Vector2, destination: Vector2, item: GlossaryItem | undefined, primaryNode: boolean) {
		this.position = position;
		this.destination = destination;
		this.item = item;
		this.renderedItem = item;
		this.primaryNode = primaryNode;
		if (this.isMainNode()) this.mainFactor = 1;
		this.updateMainColor();
	}

	public changeContent(canvasContext: CanvasRenderingContext2D | null, item: GlossaryItem | undefined, primaryNode: boolean) {
		const wasMainNode = this.isMainNode();

		this.item = item;
		if (item) this.renderedItem = item;
		this.primaryNode = primaryNode;

		if (wasMainNode !== this.isMainNode()) {
			if (this.tween) this.tween.stop();
			this.tween = new TWEEN.Tween({ mainFactor: this.mainFactor });
			this.tween.to({ mainFactor: this.isMainNode() ? 1 : 0 }, ANIMATION_DURATION);
			this.tween.onUpdate((tweenedObject) => { this.mainFactor = tweenedObject.mainFactor });
			this.tween.onComplete((_) => {
				if (this.mainFactor === 0) {
					this.renderedItem = undefined;
					this.renderedMainColor = this.mainColor;
				}
			});
			this.tween.easing(TWEEN.Easing.Quadratic.InOut);
			this.tween.start(TWEEN.now());
		}

		this.updateMainColor();

		if (canvasContext && this.renderedItem && !this.primaryNode) {
			this.initializeText(canvasContext);
		} else {
			this.rightDestinationOffset = this.maxRadius;
		}
	}

	public updatePosition() {
		this.acceleration = this.destination.subtract(this.position).scale(ACCELERATION_FACTOR);
		this.velocity = this.velocity.add(this.acceleration);
		this.velocity = this.velocity.scale(this.frictionFactor);
		this.position = this.position.add(this.velocity);
	}

	public renderFrame(time: number, canvasContext: CanvasRenderingContext2D, mousePosition: Vector2) {
		this.tween.update(time);

		if (this.mainFactor > 0) {
			canvasContext.strokeStyle = this.getColorString(this.getInterpolatedColor());
			canvasContext.lineWidth = 2 * window.devicePixelRatio;
			canvasContext.beginPath();
			canvasContext.arc(this.position.x, this.position.y, this.circleRadius * this.mainFactor, Math.PI * 2, 0, false);
			canvasContext.stroke();
			canvasContext.closePath();
		}

		this.renderPoint(canvasContext);

		if (this.mainFactor > 0) {
			this.rightOffset = this.circleRadius;
			this.maxRadius = this.circleRadius;

			if (this.renderedItem) this.renderTextRectangle(canvasContext, false);
		}
	}

	public updateDestination(destination: Vector2) {
		this.destination = destination;
	}

	public teleport(deltaVector: Vector2) {
		this.position = this.position.add(deltaVector);
		this.destination = this.destination.add(deltaVector);
	}

	public isItemNode() {
		return this.item !== undefined;
	}

	public isMainNode() {
		return this.primaryNode || this.isItemNode();
	}

	public isPrimaryNode() {
		return this.primaryNode;
	}

	public getItem() {
		return this.item;
	}

	public getSpeed() {
		return this.velocity.length;
	}

	public getExtents(): RectangleExtents {
		let extents = new RectangleExtents();
		extents.minX = this.position.x - this.maxRadius;
		extents.maxX = this.position.x + this.rightOffset;
		extents.minY = this.position.y - this.maxRadius;
		extents.maxY = this.position.y + this.maxRadius;
		return extents;
	}

	public getDestinationExtents(): RectangleExtents {
		let extents = new RectangleExtents();
		extents.minX = this.destination.x - this.maxRadius;
		extents.maxX = this.destination.x + this.rightDestinationOffset;
		extents.minY = this.destination.y - this.maxRadius;
		extents.maxY = this.destination.y + this.maxRadius;
		return extents;
	}

	public containsCoordinates(coords: Vector2): boolean {
		const { minX, maxX, minY, maxY } = this.getExtents();
		if (coords.y > minY && coords.y < maxY &&
			coords.x > minX && coords.x < maxX) {
			return true;
		} else {
			return false;
		}
	}

	private initializeText(canvasContext: CanvasRenderingContext2D) {
		canvasContext.textAlign = "start";
		canvasContext.textBaseline = "middle";
		canvasContext.font = `${16 * window.devicePixelRatio}px sans-serif`;
		this.textMetrics = canvasContext.measureText(this.renderedItem?.title ?? "");

		this.rightDestinationOffset = this.circleRadius + this.circleMargin + (this.textMetrics.width + this.rectanglePadding);
	}

	private updateMainColor() {
		this.mainColor = (this.primaryNode) ? PRIMARY_NODE_COLOR : MAIN_NODE_COLOR;
		if (this.primaryNode) this.renderedMainColor = this.mainColor;
	}

	private getInterpolatedColor() {
		return {
			r: this.interpolate(BACKGROUND_NODE_COLOR.r, this.renderedMainColor.r),
			g: this.interpolate(BACKGROUND_NODE_COLOR.g, this.renderedMainColor.g),
			b: this.interpolate(BACKGROUND_NODE_COLOR.g, this.renderedMainColor.b),
		};
	}

	private interpolate(from: number, to: number) {
		const delta = to - from;
		return from + delta * this.mainFactor;
	}

	private getColorString(color: Color) {
		return `rgb(${color.r}, ${color.g}, ${color.b})`;
	}

	private renderPoint(canvasContext: CanvasRenderingContext2D) {
		const radius = this.pointRadius + this.mainRadiusDelta * this.mainFactor;
		canvasContext.fillStyle = this.getColorString(this.getInterpolatedColor());
		canvasContext.beginPath();
		canvasContext.arc(this.position.x, this.position.y, radius, Math.PI * 2, 0, false);
		canvasContext.fill();
		canvasContext.closePath();
		this.rightOffset = radius;
		this.maxRadius = radius;
	}

	private renderTextRectangle(canvasContext: CanvasRenderingContext2D, rounded: boolean) {
		this.initializeText(canvasContext);
		if (!this.textMetrics) return;
		let rectanglePosition = this.position.add(new Vector2(this.circleRadius + this.circleMargin + this.rectanglePadding, 0).scale(this.mainFactor));

		canvasContext.fillStyle = this.getColorString(this.getInterpolatedColor());
		let width = (this.textMetrics.width + this.rectanglePadding * 2) * this.mainFactor;
		let height = (this.textMetrics.actualBoundingBoxAscent + this.textMetrics.actualBoundingBoxDescent + this.rectanglePadding * 2) * this.mainFactor;
		let x = rectanglePosition.x - this.rectanglePadding * this.mainFactor;
		let y = rectanglePosition.y - height / 2;

		if (rounded) {
			let radius = this.mainPointRadius;
			canvasContext.beginPath();
			canvasContext.moveTo(x + radius, y);
			canvasContext.arcTo(x + width, y, x + width, y + height, radius);
			canvasContext.arcTo(x + width, y + height, x, y + height, radius);
			canvasContext.arcTo(x, y + height, x, y, radius);
			canvasContext.arcTo(x, y, x + width, y, radius);
			canvasContext.closePath();
			canvasContext.fill();

		} else {
			canvasContext.beginPath();
			canvasContext.rect(x, y, width, height);
			canvasContext.closePath();
			canvasContext.fill();
		}

		canvasContext.fillStyle = `rgba(255, 255, 255, ${this.mainFactor})`;
		canvasContext.fillText(this.renderedItem?.title ?? "", rectanglePosition.x, rectanglePosition.y);

		this.rightOffset = (x + width) - this.position.x;
		this.maxRadius = Math.max(this.maxRadius, height / 2);
	}
}

export default Particle;