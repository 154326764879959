import React from 'react';
import Vector2 from './components/Vector2';
import Particle from './components/Particle';
import Line from './components/Line';
import { GlossaryItem } from '../../types/glossary';
import RectangleExtents from './components/RectangleExtents';

interface GraphProps {
	selectedItem: GlossaryItem;
	referencedItems: GlossaryItem[];
	onSelect: (item: GlossaryItem, push: boolean) => void;
}

const width = 400;
const minHeight = 450;

const horizontalPadding = 50 * window.devicePixelRatio;
const verticalPadding = 25 * window.devicePixelRatio;

class Graph extends React.Component<GraphProps> {
	private numParticles = 42;

	private canvasRef = React.createRef<HTMLCanvasElement>();
	private canvasContext: CanvasRenderingContext2D | null = null;
	private backgroundParticles: Particle[] = [];
	private mainParticles: Particle[] = [];
	private allParticles: Particle[] = [];
	private hoveredParticle: Particle | undefined;
	private lines: Line[] = [];
	private mousePosition = new Vector2(-999999, -999999);

	private canvasWidth = 0;
	private canvasHeight = 0;

	private redistributeParticles = false;
	private renderFrames = true;

	public componentDidMount() {
		if (this.canvasRef.current) {
			let canvas = this.canvasRef.current;
			this.canvasContext = canvas.getContext("2d");
			this.udpdateCanvasDimensions(canvas, true);

			window.addEventListener("resize", this.onResize);
			window.addEventListener("mousemove", this.onMouseMove);
			window.addEventListener("touchstart", this.onTouch);
			window.addEventListener("touchmove", this.onTouch);

			if (this.canvasContext) {
				this.createParticles();
				this.createLines();

				this.renderFrame(0);
			}
		}
	}

	public componentDidUpdate(prevProp: GraphProps) {
		if (this.props.selectedItem.id !== prevProp.selectedItem.id) {
			this.reconfigureGraph(this.canvasContext);
		}
	}

	private setPointer(enabled: boolean) {
		if (this.canvasRef.current) {
			this.canvasRef.current.style.cursor = enabled ? "pointer" : "default";
		}
	}

	private onClick = () => {
		if (this.hoveredParticle) {
			const item = this.hoveredParticle.getItem();
			if (item) {
				this.props.onSelect(item, true);
			}
		}
	}

	private onMouseMove = (ev: MouseEvent) => {
		if (this.canvasContext) {
			const rect = this.canvasContext.canvas.getBoundingClientRect();
			this.mousePosition.x = (ev.clientX - rect.left) * window.devicePixelRatio;
			this.mousePosition.y = (ev.clientY - rect.top) * window.devicePixelRatio;

			this.hoveredParticle = undefined;

			this.mainParticles.forEach(particle => {
				if (!particle.isPrimaryNode() && particle.containsCoordinates(this.mousePosition)) {
					this.hoveredParticle = particle;
				}
			});

			this.setPointer(this.hoveredParticle !== undefined);
		}
	}

	private onTouch = (ev: TouchEvent) => {
		if (ev.touches.length > 0 && this.canvasContext) {
			const rect = this.canvasContext.canvas.getBoundingClientRect();
			this.mousePosition.x = (ev.touches[0].clientX - rect.left) * window.devicePixelRatio;
			this.mousePosition.y = (ev.touches[0].clientY - rect.top) * window.devicePixelRatio;
			// ev.preventDefault();
		}
	}

	private createParticles() {
		this.mainParticles.push(new Particle(this.getRandomMainPosition(), this.getRandomMainPosition(), undefined, true));

		for (let i = 0; i < Math.min(this.props.referencedItems.length, this.numParticles); i++) {
			const referencedItem = this.props.referencedItems[i];
			this.mainParticles.push(new Particle(this.getRandomMainPosition(), this.getRandomMainPosition(), referencedItem, false));
		}

		for (var i = this.props.referencedItems.length; i < this.numParticles - 1; i++) {
			this.backgroundParticles.push(new Particle(this.getRandomPosition(), this.getRandomPosition(), undefined, false));
		}

		this.allParticles = [...this.backgroundParticles, ...this.mainParticles];
		this.redistributeParticles = true;
	}

	private createLines() {
		for (var i = 0; i < this.numParticles; i++) {
			for (var j = i + 1; j < this.numParticles; j++) {
				this.lines.push(new Line(this.allParticles[i], this.allParticles[j]));
			}
		}
	}

	private renderFrame = (time: number) => {

		if (this.renderFrames) {
			if (this.canvasRef && this.canvasRef.current && this.canvasContext) {
				let totalSpeed = 0;

				this.allParticles.forEach((particle) => particle.updatePosition());

				this.canvasContext.clearRect(0, 0, this.canvasRef.current.width, this.canvasRef.current.height);
				this.lines.forEach(line => line.renderFrame(this.canvasContext!));
				this.allParticles.forEach((particle) => {
					particle.renderFrame(time, this.canvasContext!, this.mousePosition);
					totalSpeed += particle.getSpeed();
				});


				this.renderFrames = totalSpeed > 0.1;
			}
		}

		requestAnimationFrame(this.renderFrame);
		this.redistributeParticlesOnce();
	}

	private redistributeParticlesOnce = () => {
		if (this.redistributeParticles) {
			this.redistributeParticles = false;
			this.renderFrames = true;
			// this.mainParticles.forEach(this.improveParticlePosition);
			let totalParticlesHeight = 0;

			this.mainParticles.forEach((particle) => totalParticlesHeight += particle.maxHeight);

			const freeSpace = this.canvasHeight - totalParticlesHeight - verticalPadding * 2;
			const shuffledMainParticles = this.getShuffledList<Particle>(this.mainParticles);

			for (let i = 0; i < shuffledMainParticles.length; i++) {
				shuffledMainParticles[i].updateDestination(this.getSmartRandomPosition(shuffledMainParticles[i].getDestinationExtents(), i, shuffledMainParticles.length, freeSpace));
			}
		}
	}

	private getShuffledList<T>(list: T[]) {
		const shuffledList: T[] = [];
		const copiedList: T[] = [];
		list.forEach((object) => copiedList.push(object));

		for (let i = 0; i < list.length; i++) {
			let index = Math.round(Math.random() * (copiedList.length - 1));
			shuffledList.push(copiedList[index]);
			copiedList.splice(index, 1);
		}

		return shuffledList;
	}

	private getSmartRandomPosition(extents: RectangleExtents, index: number, count: number, freeSpace: number) {
		const rightOffset = extents.maxX - extents.minX;
		const height = (extents.maxY - extents.minY);
		const verticalPosition = ((this.canvasHeight - verticalPadding * 2) * ((index + 1) / count)) + verticalPadding;
		const verticalOffset = (height / 2) + Math.random() * (freeSpace / count);
		return new Vector2(Math.max(Math.random() * (this.canvasWidth - rightOffset - horizontalPadding * 2), - horizontalPadding / 2) + horizontalPadding, verticalPosition - verticalOffset);
	}

	private getRandomMainPosition() {
		return new Vector2((Math.random() * (this.canvasWidth - horizontalPadding * 4)) + horizontalPadding, (Math.random() * (this.canvasHeight - verticalPadding * 2)) + verticalPadding);
	}

	private getRandomPosition() {
		// return new Vector2(Math.random() * this.canvasWidth, Math.random() * this.canvasHeight);
		return new Vector2((Math.random() * (this.canvasWidth - horizontalPadding * 2)) + horizontalPadding, (Math.random() * (this.canvasHeight - verticalPadding * 2)) + verticalPadding);
	}

	private onResize = () => {
		if (this.canvasContext) {
			const canvas = this.canvasContext.canvas;
			this.udpdateCanvasDimensions(canvas);

			this.repositionGraph();
		}
	}

	private udpdateCanvasDimensions(canvas: HTMLCanvasElement, initialize: boolean = false) {
		this.canvasWidth = canvas.clientWidth * window.devicePixelRatio;
		this.canvasHeight = canvas.clientHeight * window.devicePixelRatio;
		if (initialize) this.canvasHeight = Math.max(window.innerHeight - canvas.offsetTop, minHeight) * window.devicePixelRatio;
		canvas.width = this.canvasWidth;
		canvas.height = this.canvasHeight;
	}

	private repositionGraph() {
		this.backgroundParticles.forEach(particle => particle.updateDestination(this.getRandomPosition()));
		this.mainParticles.forEach(particle => particle.updateDestination(this.getRandomMainPosition()));
		this.redistributeParticles = true;
		this.renderFrames = true;
	}

	private reconfigureGraph = (canvasContext: CanvasRenderingContext2D | null) => {
		this.mainParticles.forEach(particle => particle.changeContent(canvasContext, undefined, false));

		for (let i = 0; i < Math.min(this.props.referencedItems.length, this.backgroundParticles.length); i++) {
			this.backgroundParticles[i].changeContent(canvasContext, this.props.referencedItems[i], false);
		}

		if (this.props.referencedItems.length >= this.backgroundParticles.length) {
			const remainingItems = this.props.referencedItems.length - this.backgroundParticles.length;
			const startIndex = this.backgroundParticles.length;
			const conditionIndex = Math.min(this.mainParticles.length, remainingItems + 1);
			for (let i = 1; i < conditionIndex; i++) {
				this.mainParticles[i].changeContent(canvasContext, this.props.referencedItems[startIndex + i - 1], false);
			}

			if (conditionIndex < this.mainParticles.length) this.mainParticles[conditionIndex].changeContent(canvasContext, undefined, true);
		} else {
			this.backgroundParticles[this.props.referencedItems.length].changeContent(canvasContext, undefined, true);
		}

		this.backgroundParticles = this.allParticles.filter(particle => !particle.isMainNode());
		this.mainParticles = this.allParticles.filter(particle => particle.isMainNode());

		this.repositionGraph();

		this.allParticles = [...this.backgroundParticles, ...this.mainParticles];
	}

	render() {
		return (
			<canvas ref={this.canvasRef}
				onClick={this.onClick}
				style={{
					width: width,
					height: "100%",
					minHeight: minHeight,
					display: "block",
					userSelect: "none",
					WebkitUserSelect: "none",
					msUserSelect: "none",
					KhtmlUserSelect: "none",
					MozUserSelect: "none",
				}} />
		);
	}
}

export default Graph;
