import React, { useState, Fragment } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Hidden, Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Fuse from 'fuse.js';

import { GlossaryItem } from '../../types/glossary';
import SearchInput from './SearchInput/SearchInput';
import NavMenu from './NavMenu/NavMenu';
import { bgOverlay } from '../../pages/Home/Home';
import Mittelstand_logo from '../../images/logo_md40_kompetenzzentrum_planen_bauen_cut.svg';
import clsx from 'clsx';

interface NavigationMenuProps {
	glossaryItems: GlossaryItem[];
	topItems: GlossaryItem[];
	selectedItem: GlossaryItem;
	onSelect: (item: GlossaryItem, push: boolean) => void;

	mobileDrawerOpen: boolean;
	closeMobileDrawer: () => void;
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			maxHeight: 'calc(100vh - 120px)',
			[theme.breakpoints.down("sm")]: {
				// maxHeight: '90vh',
				// paddingBottom: '30px',
				maxHeight: '100%',
				height: '100%',
				backgroundColor: '#d1d1d1',
			},
		},
		drawer: {
			backgroundColor: '#d1d1d1',
			// backgroundImage: bgOverlay,
			height: "100%",
		},
		topBar: {
			height: 60,
			marginTop: 30,
			marginBottom: 30,
		},
	}),
);

const NavigationMenu: React.FC<NavigationMenuProps> = (props) => {
	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');

	const [categoryItems, setCategoryItems] = useState<Array<string>>([]);

	const options = {
		keys: [
			'title',
			// 'description',
		],
		threshold: 0.4,
	};
	const fuse = new Fuse(props.glossaryItems, options);

	const filteredItems = (searchTerm.length > 0 ? fuse.search(searchTerm).map(
		result => {
			return result.item
		}
	) : props.glossaryItems);

	const highlightedGlossarItems = filteredItems.filter(item => {
		if (categoryItems.length < 1) {
			return false;
		}
		return categoryItems.includes(item.category);
	});


	const titles = filteredItems.map(item => item.title);
	const categories = props.glossaryItems.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index && value !== undefined && value !== '').sort((a, b) => a.localeCompare(b));

	const navigationMenu = () => {
		return (<Box pt={2} className={classes.root} display="flex" flexDirection="column">
			<Hidden mdUp>
				<Box py={2}>
					<IconButton onClick={props.closeMobileDrawer}>
						<CloseIcon style={{ fontSize: 32 }} />
					</IconButton>
				</Box>
			</Hidden>


			<Box pb={2} borderBottom="solid 1px #FFF">
				<SearchInput searchChanged={(search) => {
					setSearchTerm(search);
				}} />
				<NavMenu
					menuTitle="Auswahl Kategorie"
					items={categories}
					selectedItems={[]}
					highlightHover={true}
					highlightedItems={categoryItems}
					onSelect={(item) => {
						setCategoryItems(prev => {
							if (prev.includes(item)) {
								return prev.filter(v => v !== item);
							}
							return [...prev, item];
						})
					}}
				/>
			</Box>
			<Box overflow="scroll" className={clsx('hideScrollbar')}>
				<NavMenu menuTitle="Glossar"
					items={titles}
					isOpenByDefault={true}
					topItems={props.topItems.map(item => item.title)}
					useInitials={true}
					selectedItems={[props.selectedItem.title]}
					highlightedItems={highlightedGlossarItems.map(item => item.title)}
					onSelect={(selection) => {
						let sItem = props.glossaryItems.find(item => item.title === selection);
						if (!sItem) {
							sItem = props.topItems.find(item => item.title === selection);
						}
						if (sItem) {
							props.onSelect(sItem, true);
							props.closeMobileDrawer();
						}
					}}
				/>
			</Box>
		</Box>);
	}

	return (
		<Fragment>
			<Hidden smDown>
				<Hidden lgUp>
					<Box pl={3} pr={3} width={280} >
						{navigationMenu()}
					</Box >
				</Hidden>
				<Hidden mdDown>
					<Box pl={8} pr={8} width={400} >
						{navigationMenu()}
					</Box >
				</Hidden>
			</Hidden>
			<Hidden mdUp>
				<Drawer anchor="left" open={props.mobileDrawerOpen} onClose={props.closeMobileDrawer} >

					{/*
					<img className={classes.mittelstandlogo}
						src={Mittelstand_logo} alt="Mittelstand 4.0 Kompetenzzentrum für Planen und Bauen" />
					*/}
					<Box pl={3} pr={3} width={320} className={classes.drawer}>
						{navigationMenu()}
					</Box>

				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default NavigationMenu;
