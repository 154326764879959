import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../../../../hooks/useStyles';

interface MenuListProps {
	topItems: string[];
	items: string[];
	useInitials?: boolean;
	selectedItems: string[];
	highlightHover?: boolean;
	highlightedItems: string[];
	onSelect: (item: string) => void;
}

interface RenderItemDef {
	type: 'categoryName' | 'simpleItem';
	key: string;
	label: string;
}

const highlightColor = "#5cc4ff";
const selectedColor = "#b50900";

const MenuList: React.FC<MenuListProps> = (props) => {
	const classes = useStyles(theme => ({
		menu: {
			padding: 0,
			'& li': {
				listStyle: 'none',
				//padding: 0,
			},
		},
		itemBox: {
			display: 'flex',
			// alignItems: 'center',
			position: 'relative',
		},
		decoration: {
			marginLeft: 5,
			marginRight: 5,
			border: 'solid 1px white',
			flexGrow: 1,
		},
		hidden: {
			border: 'none'
		},
		circleBox: {
			position: 'relative',
			marginLeft: -20,
			marginRight: 15,
			marginTop: 'auto',
			marginBottom: 'auto',
			height: 28,
		},
		itemDot: {
			position: 'absolute',
			top: 11,
			left: 11,
			borderRadius: '50%',
			backgroundColor: 'white',
			width: 6,
			height: 6,
		},
		selectedDot: {
			backgroundColor: selectedColor,
		},
		selectedCircle: {
			position: 'absolute',
			borderRadius: '50%',
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: selectedColor,
			width: 28,
			height: 28,
		},
		highlightedDot: {
			backgroundColor: highlightColor,
		},
		highlightedCircle: {
			position: 'absolute',
			borderRadius: '50%',
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: highlightColor,
			width: 28,
			height: 28,
		},
		categoryName: {
			color: 'white',
			fontWeight: "bolder",
		},
		simpleItem: {
			'&:hover': {
				backgroundColor: props.highlightHover ? highlightColor : selectedColor,
				color: 'white',
				cursor: 'pointer',
			}
		},
		selectedItem: {
			backgroundColor: selectedColor,
			color: 'white'
		},
		highlightedItem: {
			backgroundColor: highlightColor,
			color: 'white'
		},
		textContent: {
			padding: 3,
			margin: 6,
			marginLeft: 18,
			paddingLeft: 6,
			width: '100%',
			textAlign: 'left'
		}
	}));

	const sorted = props.items.sort((a, b) => a.localeCompare(b));
	const renderList: RenderItemDef[] = [
		...props.topItems.map(item => {
			return { label: item, key: item, type: 'simpleItem' } as RenderItemDef;
		})
	];
	let currentInitial: string | undefined;
	sorted.forEach(item => {
		if (props.useInitials && (!currentInitial || currentInitial !== item[0])) {
			currentInitial = item[0];
			renderList.push({ label: currentInitial.toUpperCase(), key: currentInitial, type: 'categoryName' });
		}
		renderList.push({ label: item, key: item, type: 'simpleItem' });
	})

	const isSelectedItem = (item: RenderItemDef): boolean => {
		return props.selectedItems.includes(item.label);
	}

	const isHighlightedItem = (item: RenderItemDef): boolean => {
		return props.highlightedItems.includes(item.label);
	}

	const addDecorationLine = (isFirst: boolean, isLast: boolean): React.ReactNode => {
		return <div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className={
				clsx(
					classes.decoration,
					{ [classes.hidden]: isFirst }
				)
			}>
			</div>
			<div className={
				clsx(
					classes.decoration,
					{ [classes.hidden]: isLast }
				)
			}>
			</div>
		</div>
	}

	const addCircles = (selected: boolean, highlighted: boolean): React.ReactFragment => {
		return <div className={classes.circleBox}>
			<div className={
				clsx(classes.itemDot, {
					[classes.selectedDot]: selected,
					[classes.highlightedDot]: highlighted
				})}></div>
			{selected || highlighted ? <div className={
				clsx({
					[classes.selectedCircle]: selected,
					[classes.highlightedCircle]: highlighted
				})
			}></div> : null
			}
		</div >
	}

	return (
		<ul className={classes.menu}>
			{renderList.map(
				(item, index) => <li
					className={clsx({ [classes.categoryName]: item.type === 'categoryName', })}
					key={item.key}
					onClick={() => {
						if (item.type !== 'categoryName') {
							props.onSelect(item.label);
						}
					}}
				>
					<div className={classes.itemBox}>
						{addDecorationLine(index === 0, index === renderList.length - 1)}
						{item.type !== 'categoryName' ?
							addCircles(isSelectedItem(item), isHighlightedItem(item)) : null
						}
						<div className={
							clsx(
								classes.textContent,
								{
									[classes.simpleItem]: item.type === 'simpleItem',
									[classes.selectedItem]: isSelectedItem(item),
									[classes.highlightedItem]: isHighlightedItem(item)
								},
							)
						}>
							{item.label}
						</div>
					</div>
				</li>
			)
			}
		</ul >
	);
};

export default MenuList;
