import React from 'react';
import './App.css';

import CssBaseline from '@material-ui/core/CssBaseline';
import Home from './pages/Home/Home';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import 'typeface-open-sans';



function App() {
	let theme = createMuiTheme({
		palette: {
			primary: {
				light: '#5b9abe',
				main: '#09132d',
			},
			secondary: {
				main: '#b50900',
			}
		},
		typography: {
			fontFamily: 'Open Sans'
		}
	});

	theme = responsiveFontSizes(theme);

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<CssBaseline />
				<Router>
					<Home />
				</Router>
			</div>
		</ThemeProvider>
	);
}

export default App;
